import React from 'react';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';
import SignupForm from '../../containers/AuthenticationPage/SignupForm/SignupForm';
import css from './AuthenticationPageMerchant.module.css'
import merchantLogin from '../../assets/images/merchantLogin.png'
import merchantSignup from '../../assets/images/merchantSignup.png'
import merchatLogo from '../../assets/merchatLogo.png'
import NamedLink from '../NamedLink/NamedLink';
import { FormattedMessage } from 'react-intl';
import { AuthenticationForms, ConfirmIdProviderInfoForm } from '../../containers/AuthenticationPage/AuthenticationPage';

const AuthenticationPageMerchant = (props) => {
  const {
    tab,
    userType,
    authInfo,
    from,
    showFacebookLogin,
    showGoogleLogin,
    showAppleLogin,
    showSnapchatLogin,
    showTikTokLogin,
    submitLogin,
    submitSignup,
    submitSignupWithIdp,
    authInProgress,
    loginError,
    idpAuthError,
    signupError,
    confirmError,
    termsAndConditions,
  } = props;

  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';

  return (
    <div className={css.container}>
      <div className={css.contentWrapper}>
        <div className={css.leftImage}>
          {isLogin ?
            <img src={merchantLogin} alt="merchant Login Banner" className={css.merchantBannerImage} />
            :
            <img src={merchantSignup} alt="merchant signup Banner" className={css.merchantBannerImage} />
          }
        </div>
        <div className={css.rightContent}>
          <div className={css.authInputs}>
            <NamedLink name="LandingPage"><img className={css.logo} src={merchatLogo} alt="Logo" /></NamedLink>
            {isLogin ? <h2 className={css.loginTitle}><FormattedMessage id={'AuthenticationPage.merchantLogin'} /></h2>
              :
              <h2 className={css.signupTitle}><FormattedMessage id={'AuthenticationPage.merchantSignup'} /></h2>}
            {isConfirm ? (
              <ConfirmIdProviderInfoForm
                userType={userType}
                authInfo={authInfo}
                submitSignupWithIdp={submitSignupWithIdp}
                authInProgress={authInProgress}
                confirmError={confirmError}
                termsAndConditions={termsAndConditions}
              />
            ) : (
              <AuthenticationForms
                isLogin={isLogin}
                showFacebookLogin={showFacebookLogin}
                showGoogleLogin={showGoogleLogin}
                showAppleLogin={showAppleLogin}
                showSnapchatLogin={showSnapchatLogin}
                showTikTokLogin={showTikTokLogin}
                userType={userType}
                from={from}
                loginError={loginError}
                idpAuthError={idpAuthError}
                signupError={signupError}
                submitLogin={submitLogin}
                authInProgress={authInProgress}
                submitSignup={submitSignup}
                termsAndConditions={termsAndConditions}
                isMerchant={true}
              ></AuthenticationForms>
            )}

            {/* <SignupForm onSubmit={() => { }} /> */}
            {/* <div className={css.socialLogin}>
              <p><FormattedMessage id='AuthenticationPage.signInWith' /></p>
              <div className={css.socialButton}>
                <span className={css.icon}>G</span>
                <span className={css.icon}>F</span>
                <span className={css.icon}>A</span>
                <span className={css.icon}>S</span>
              </div>
            </div>
            <div className={css.footerLinks}>
              <NamedLink name="SignupPage" className={css.recoveryLink}>
                <FormattedMessage id='AuthenticationPage.newUser' />

              </NamedLink>
              <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
                <FormattedMessage id="LoginForm.forgotPassword" />
              </NamedLink>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    //   <div>
    //    {true ? (
    //   <LoginForm className={css.loginForm} onSubmit={()=>{}} />
    // ) : (
    //   <SignupForm
    //     className={css.signupForm}
    //     onSubmit={()=>{}}

    //   />
    // )}
    //   </div>
  );
};

export default AuthenticationPageMerchant;