import classNames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import customerLogo from '../../assets/customerLogo.png';
import { useRouteConfiguration } from "../../context/routeConfigurationContext";
import { createResourceLocatorString } from "../../util/routes";
import { DEAL_LISTING_TYPE, SERVICE_LISTING_TYPE } from "../../util/types";
import { createSlug } from "../../util/urlHelpers";
import IconCollection from "../IconCollection/IconCollection";
import css from "./BookingDetailsBanner.module.css";

const BookingDetailsBanner = ({ checkoutData, hideBookingButton }) => {
    if (!checkoutData) return <></>
    const { listing, orderData, merchantDetails } = checkoutData || {};
    const { attributes, images } = listing || {};
    const { title, price, publicData } = attributes || {};
    const { amount, currency } = price || {};
    const { location, name: merchantName } = merchantDetails || {};
    const { address, building } = location || {};

    const { listingType } = publicData || {};
    const isBooking = listingType === SERVICE_LISTING_TYPE;

    const routeConfiguration = useRouteConfiguration();

    const firstImageUrl = images && images?.[0]?.attributes?.variants?.['listing-card']?.url;

    // Get the first item dynamically from the cart object
    const firstItemKey = orderData && orderData.cart ? Object.keys(orderData.cart)[0] : null;
    const firstItem = firstItemKey ? orderData.cart[firstItemKey] : null;

    const innerItemKey = firstItem ? Object.keys(firstItem)[0] : null;
    const innerItem = innerItemKey ? firstItem[innerItemKey] : null;

    const isDeal = innerItem?.type === DEAL_LISTING_TYPE;

    const bookingStart = isDeal ? innerItem?.dealListingIds?.[0]?.bookingStart : innerItem?.bookingStart;
    const bookingEnd = isDeal ? innerItem?.dealListingIds?.[0]?.bookingEnd : innerItem?.bookingEnd;
    const staffId = isDeal ? innerItem?.dealListingIds?.[0]?.staffId : innerItem?.staffId;

    let formattedStartDate, formattedStartTime, formattedEndTime, duration;
    if (bookingStart && bookingEnd) {
        const startMoment = moment(parseInt(bookingStart));
        const endMoment = moment(parseInt(bookingEnd));

        formattedStartDate = startMoment.format('dddd, Do MMMM');
        formattedStartTime = startMoment.format('h:mm a');
        formattedEndTime = endMoment.format('h:mm a');

        duration = endMoment.diff(startMoment, 'minutes');
    }

    const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

    const linkData = {
        // serviceId: innerItemKey,
        staffId: staffId,
        bookingStart: bookingStart,
        bookingEnd: bookingEnd
    }

    // Construct a query string
    const queryString = new URLSearchParams(linkData).toString();

    const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
    const listingUrl = createResourceLocatorString('ListingPage', routeConfiguration, { id: innerItemKey, slug: createSlug(title || "") }, { bookingLink: true });
    const listingUrlFull = ROOT_URL + listingUrl;

    const whatsappMessage = `Hello NOW Offers! , I’d like to book this ${isDeal ? 'deal' : 'service'} *${title}* for *${currency} ${(amount / 100)?.toFixed(2)}* by this merchant *${merchantName}* on your platform on *${formattedStartDate} ${formattedStartTime} - ${formattedEndTime} (${duration} mins).* Please share payment details. Thanks!
    ${listingUrlFull}&${queryString}`;

    const whatsappLink = `https://wa.me/966539900466?text=${encodeURIComponent(whatsappMessage)}`;

    return (
        <div className={arabicLanguage && css.arabicDirection}>
            <div className={classNames(css.bookingDetails)}>
                <div>
                    {firstImageUrl ? <img className={css.userImage} src={firstImageUrl} alt={title} /> : <img className={css.userImage} src={customerLogo} alt="Customer logo" />}
                </div>
                <div className={css.detailsBooking}>
                    <div className={css.title}>
                        <span>{title}</span>
                        {amount && currency ? (
                            <span> / {currency} {amount / 100}</span>
                        ) : null}
                    </div>
                    <div className={css.detailsText}>
                        {merchantName && <span>{merchantName}</span>}

                        {formattedStartDate && (
                            <div>
                                <span>{formattedStartDate}</span>
                                <div>
                                    {formattedStartTime && formattedEndTime && (
                                        <span>{formattedStartTime} - {formattedEndTime} ({duration} mins)</span>
                                    )}
                                </div>
                            </div>
                        )}

                        {address && (
                            <p className={css.address}>
                                <FormattedMessage id="BookingDetailsBanner.address" /> {building ? `${building}, ${address}` : address}
                            </p>
                        )}

                        {(isBooking || isDeal) && !hideBookingButton ?
                            <div className={css.bookOnWhatsapp}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    typeof window !== undefined && window.open(whatsappLink, '_blank');
                                }}>

                                <IconCollection name="whatsapp" />
                                <span><FormattedMessage id='ServiceCard.completeBookOnWhatsapp' /></span>
                            </div> : null}
                    </div>
                </div>
            </div>

            <div className={css.poweredByBanner}>
                <div className={css.bannerContent}>
                    <p className={css.poweredText}><FormattedMessage id="ViewBusiness.poweredBy" /></p>
                    <IconCollection name="poweredByIcon" />
                </div>
                <span className={css.backIcon}><IconCollection name="poweredBy" /></span>
            </div>
            {/* <div className={css.bookOnWhatsapp}>
                <IconCollection name="whatsapp" />
                <span><FormattedMessage id='ServiceCard.completeBookOnWhatsapp' /></span>
            </div> */}
        </div>
    );
};

export default BookingDetailsBanner;