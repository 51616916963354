import classNames from 'classnames';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { bool, func, object, oneOf, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  H1,
  Heading,
  LayoutSingleColumn,
  LinkTabNavHorizontal,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveBackgroundImageContainer,
  ResponsiveImage,
  SocialLoginButton,
} from '../../components';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { authenticationInProgress, login, signup, signupWithIdp } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isSignupPhoneTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import { propTypes } from '../../util/types';
import { addScopePrefix, pickUserFieldsData } from '../../util/userHelpers';
import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import EmailVerificationInfo from './EmailVerificationInfo';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

// We need to get ToS asset and get it rendered for the modal on this page.

// We need to get PrivacyPolicy asset and get it rendered for the modal on this page.
import { useHistory } from 'react-router-dom';
import merchantFacebook from '../../assets/images/socials/facebook.png';
import merchantGoogle from '../../assets/images/socials/google.png';
import merchantSnap from '../../assets/images/socials/snap.png';
import merchantTictoc from '../../assets/images/socials/tiltokpng.png';
import AuthenticationPageMerchant from '../../components/AuthenticationPageMerchant/AuthenticationPageMerchant';
import BookingDetailsBanner from '../../components/BookingDetailsBanner/BookingDetailsBanner';
import { FacebookLogo } from '../../components/SocialLoginLogos/FacebookLogo';
import { GoogleLogo } from '../../components/SocialLoginLogos/GoogleLogo';
import { SnapchatLogo } from '../../components/SocialLoginLogos/SnapchatLogo';
import { TikTokLogo } from '../../components/SocialLoginLogos/TiktokLogo';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { createSlug, parse } from '../../util/urlHelpers';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import css from './AuthenticationPage.module.css';
import IconCollection from '../../components/IconCollection/IconCollection';
import moment from 'moment';
import CustomerFooter from '../LandingPage/CustomerHomePage/CustomerFooter/CustomerFooter';

// Social login buttons are needed by AuthenticationForms
export const SocialLoginButtonsMaybe = props => {
  const routeConfiguration = useRouteConfiguration();
  const {
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    showAppleLogin,
    showSnapchatLogin,
    showTikTokLogin,
    from,
    isMerchant,
    userType,
  } = props;

  const showSocialLogins =
    showFacebookLogin || showGoogleLogin || showAppleLogin || showSnapchatLogin || showTikTokLogin;

  const getDataForSSORoutes = () => {
    const baseUrl = apiBaseUrl();

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routeConfiguration);

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routeConfiguration);

    const queryParams = new URLSearchParams({
      ...(defaultReturn ? { defaultReturn } : {}),
      ...(defaultConfirm ? { defaultConfirm } : {}),
      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      ...(from ? { from } : {}),
      // The preselected userType needs to be saved over the visit to identity provider's service
      ...(userType ? { userType } : {}),
    });

    return { baseUrl, queryParams: queryParams.toString() };
  };

  const authWith = provider => () => {
    const { baseUrl, queryParams } = getDataForSSORoutes();
    window.location.href = `${baseUrl}/api/auth/${provider}?${queryParams}`;
  };

  return showSocialLogins && isMerchant ? (
    <div className={css.socialLogin}>
      <p>
        {isLogin ? (
          <FormattedMessage id="AuthenticationPage.signInWith" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signUpWith" />
        )}{' '}
      </p>
      <div className={css.socialButton}>
        {false && showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={authWith('facebook')}>
              <span className={css.buttonIcon}>
                <ResponsiveImage
                  localImageSrc={merchantFacebook}
                  alt="facebook"
                  variants="custom-image"
                />
              </span>
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={authWith('google')}>
              <span className={css.buttonIcon}>
                <IconCollection name="google_icon" />
              </span>
            </SocialLoginButton>
          </div>
        ) : null}


        {showAppleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={authWith('apple')}>
              <span className={css.buttonIcon}>
                <IconCollection name="apple_icon" />
              </span>
            </SocialLoginButton>
          </div>
        ) : null}

        {false && showSnapchatLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={authWith('snapchat')}>
              <span className={css.buttonIcon}>
                <ResponsiveImage
                  localImageSrc={merchantSnap}
                  alt="snap chat"
                  variants="custom-image"
                />
              </span>
            </SocialLoginButton>
          </div>
        ) : null}

        {false && showTikTokLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={authWith('tiktok')}>
              <span className={css.buttonIcon}>
                <ResponsiveImage
                  localImageSrc={merchantTictoc}
                  alt="tiktok"
                  variants="custom-image"
                />
              </span>
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className={css.idpButtons}>
      {false && showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWith('facebook')}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWith('google')}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      {showAppleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWith('apple')}>
            <span className={css.buttonIcon}><IconCollection name="apple_icon" /></span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithApple" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithApple" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      {false && showSnapchatLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWith('snapchat')}>
            <span className={css.buttonIcon}>{SnapchatLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithSnapchat" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithSnapchat" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}
      {false && showTikTokLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWith('tiktok')}>
            <span className={css.buttonIcon}>{TikTokLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithTikTok" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithTikTok" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>
    </div>
  );
};

const getNonUserFieldParams = (values, userFieldConfigs) => {
  const userFieldKeys = userFieldConfigs.map(({ scope, key }) => addScopePrefix(scope, key));

  return Object.entries(values).reduce((picked, [key, value]) => {
    const isUserFieldKey = userFieldKeys.includes(key);

    return isUserFieldKey
      ? picked
      : {
        ...picked,
        [key]: value,
      };
  }, {});
};

const guestUserCheckout = (
  history,
  routeConfiguration,
  callSetInitialValues,
  onInitializeCardPaymentData
) => {
  const initialValues =
    typeof window !== 'undefined' && window.localStorage.getItem('checkoutData')
      ? JSON.parse(window.localStorage.getItem('checkoutData'))
      : {};

  const listing = initialValues?.listing || {};
  const listingId = listing?.id?.uuid;
  const { displayName: authorName } = listing?.author?.attributes?.profile || {};

  const { setInitialValues } = findRouteByRouteName('CheckoutPage', routeConfiguration);
  callSetInitialValues(setInitialValues, initialValues, true);

  // Clear previous Stripe errors from store if there is any
  onInitializeCardPaymentData();

  // Redirect to CheckoutPage
  history.push(
    createResourceLocatorString(
      'CheckoutPage',
      routeConfiguration,
      { id: listingId, slug: createSlug(authorName) },
      { bookingLink: true }
    )
  );
};

const formatDateOfBirth = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : "";
};

// Tabs for SignupForm and LoginForm
export const AuthenticationForms = props => {
  const {
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    showAppleLogin,
    showSnapchatLogin,
    showTikTokLogin,
    userType,
    from,
    submitLogin,
    loginError,
    idpAuthError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
    isMerchant,
    callSetInitialValues,
    onInitializeCardPaymentData,
    history,
    routeConfiguration,
    isBookingLink,
    checkoutData,
  } = props;
  const config = useConfiguration();
  const { userFields, userTypes = [] } = config.user;
  const preselectedUserType = userTypes.find(conf => conf.userType === userType)?.userType || null;

  const fromMaybe = from ? { from } : null;
  const signupRouteName = !!preselectedUserType ? 'SignupForUserTypePage' : 'SignupPage';
  const userTypeMaybe = preselectedUserType ? { userType: preselectedUserType } : null;
  const fromState = { state: { ...fromMaybe, ...userTypeMaybe } };
  const tabs = [
    {
      text: (
        <Heading as={!isLogin ? 'h1' : 'h1'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </Heading>
      ),
      selected: !isLogin,
      linkProps: {
        name: signupRouteName,
        params: userTypeMaybe,
        to: fromState,
      },
    },
    {
      text: (
        <Heading as={isLogin ? 'h1' : 'h1'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </Heading>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const handleSubmitSignup = values => {
    const {
      userType,
      email,
      password,
      fname,
      lname,
      displayName,
      business_name,
      confirmPassword,
      isPasswordMismatch,
      prot_dateOfBirth,
      ...rest
    } = values;
    const displayNameMaybe = displayName ? { displayName: displayName.trim() } : {};
    const updatedDOB = formatDateOfBirth(prot_dateOfBirth);

    if (values.mobile_phone_number?.hasOwnProperty('value')) {
      delete values.mobile_phone_number.value;
    }

    const params = {
      email,
      password,
      firstName: fname.trim(),
      lastName: lname.trim(),
      ...displayNameMaybe,
      publicData: {
        ...pickUserFieldsData(rest, 'public', userType, userFields),
        userType,
        business_name,
        notificationPermission: true, //Explicitly making it true to sync with app
      },
      privateData: {
        ...pickUserFieldsData(rest, 'private', userType, userFields),
      },
      protectedData: {
        ...pickUserFieldsData(rest, 'protected', userType, userFields),
        ...getNonUserFieldParams(rest, userFields),
        dateOfBirth: updatedDOB,
      },
    };

    submitSignup(params).then(res => {
      if (isBookingLink) {
        guestUserCheckout(
          history,
          routeConfiguration,
          callSetInitialValues,
          onInitializeCardPaymentData
        );
      }
    });
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const idpAuthErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.idpAuthFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : isSignupPhoneTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedPhoneAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const loginOrSignupError =
    isLogin && !!idpAuthError
      ? idpAuthErrorMessage
      : isLogin && !!loginError
        ? loginErrorMessage
        : !!signupError
          ? signupErrorMessage
          : null;

  return (
    <div className={isMerchant ? css.merchantContent : css.content}>
      {loginOrSignupError}
      {isBookingLink ? (
        <div>
          <BookingDetailsBanner checkoutData={checkoutData} />
          <div className={css.orContinue}>
            <FormattedMessage id="AuthenticationPage.orContinue" />
          </div>
        </div>
      ) : null}
      {!isMerchant ? (
        <>
          <LinkTabNavHorizontal authTabs={true} className={css.tabs} tabs={tabs} />
          <div className={css.socialLinkButtons}>
            <h2 className={css.welcomeHeading}>
              <FormattedMessage id="AuthenticationPage.heading" />
            </h2>
            <p className={css.welcomeSubHeading}>
              <FormattedMessage id="AuthenticationPage.subHeading" />
            </p>
          </div>
          <div className={css.socialButton}>
            <SocialLoginButtonsMaybe
              isLogin={isLogin}
              showFacebookLogin={showFacebookLogin}
              showGoogleLogin={showGoogleLogin}
              showAppleLogin={showAppleLogin}
              showTikTokLogin={showTikTokLogin}
              showSnapchatLogin={showSnapchatLogin}
              {...fromMaybe}
              {...userTypeMaybe}
            />
          </div>
        </>
      ) : null}

      {isLogin ? (
        <>
          <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
          {isMerchant ? (
            <div className={css.socialButton}>
              {/* <p><FormattedMessage id='AuthenticationPage.signUpWith' /></p> */}
              <SocialLoginButtonsMaybe
                isLogin={isLogin}
                showFacebookLogin={showFacebookLogin}
                showGoogleLogin={showGoogleLogin}
                showAppleLogin={showAppleLogin}
                showTikTokLogin={showTikTokLogin}
                showSnapchatLogin={showSnapchatLogin}
                {...fromMaybe}
                {...userTypeMaybe}
                isMerchant={isMerchant}
              />

              <div className={css.footerLinks}>
                <NamedLink
                  name="SignupForUserTypePage"
                  params={{ userType: 'provider' }}
                  className={css.signupLink}
                >
                  <FormattedMessage id="AuthenticationPage.newUser" />
                </NamedLink>
                <NamedLink
                  name="PasswordRecoveryPage"
                  params={{ userType: 'merchant' }}
                  className={css.recoveryLink}
                >
                  <FormattedMessage id="LoginForm.forgotPassword1" />
                </NamedLink>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            termsAndConditions={termsAndConditions}
            preselectedUserType={preselectedUserType}
            userTypes={userTypes}
            userFields={userFields}
            isMerchant={isMerchant}
          />
          {isMerchant ? (
            <div className={css.socialButton}>
              {/* <p><FormattedMessage id='AuthenticationPage.signInWith' /></p> */}
              <SocialLoginButtonsMaybe
                isLogin={isLogin}
                showFacebookLogin={showFacebookLogin}
                showGoogleLogin={showGoogleLogin}
                showAppleLogin={showAppleLogin}
                showTikTokLogin={showTikTokLogin}
                showSnapchatLogin={showSnapchatLogin}
                isMerchant={isMerchant}
                {...fromMaybe}
                {...userTypeMaybe}
              />

              <div className={css.footerLinks}>
                <NamedLink
                  name="LoginForUserTypePage"
                  params={{ userType: 'provider' }}
                  className={css.signupLink}
                >
                  <FormattedMessage id="AuthenticationPage.login" />
                </NamedLink>
                <NamedLink
                  name="PasswordRecoveryPage"
                  params={{ userType: 'merchant' }}
                  className={css.recoveryLink}
                >
                  <FormattedMessage id="LoginForm.forgotPassword1" />
                </NamedLink>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

// Form for confirming information from IdP (e.g. Facebook)
// This is shown before new user is created to Marketplace API
export const ConfirmIdProviderInfoForm = props => {
  const {
    userType,
    authInfo,
    authInProgress,
    confirmError,
    submitSignupWithIdp,
    termsAndConditions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    history,
    routeConfiguration,
    isBookingLink,
    checkoutData,
  } = props;
  const config = useConfiguration();
  const { userFields, userTypes } = config.user;
  const preselectedUserType = userTypes.find(conf => conf.userType === userType)?.userType || null;

  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

  const handleSubmitConfirm = values => {
    const { idpToken, idpId, email, firstName, lastName } = authInfo;

    const {
      userType,
      email: newEmail,
      firstName: newFirstName,
      lastName: newLastName,
      displayName,
      business_name,
      prot_dateOfBirth,
      ...rest
    } = values;

    const displayNameMaybe = displayName ? { displayName: displayName.trim() } : {};
    const updatedDOB = formatDateOfBirth(prot_dateOfBirth);

    // Pass email, fistName or lastName to Marketplace API only if user has edited them
    // and they can't be fetched directly from idp provider (e.g. Facebook)

    const authParams = {
      ...(newEmail !== email && { email: newEmail }),
      ...(newFirstName !== firstName && { firstName: newFirstName }),
      ...(newLastName !== lastName && { lastName: newLastName }),
    };

    // Pass other values as extended data according to user field configuration
    const extendedDataMaybe = !isEmpty(rest)
      ? {
        publicData: {
          ...pickUserFieldsData(rest, 'public', userType, userFields),
          userType,
          business_name,
          notificationPermission: true, //Explicitly making it true to sync with app
        },
        privateData: {
          ...pickUserFieldsData(rest, 'private', userType, userFields),
        },
        protectedData: {
          ...pickUserFieldsData(rest, 'protected', userType, userFields),
          // If the confirm form has any additional values, pass them forward as user's protected data
          ...getNonUserFieldParams(rest, userFields),
          dateOfBirth: updatedDOB,
        },
      }
      : {};

    submitSignupWithIdp({
      idpToken,
      idpId,
      ...authParams,
      ...displayNameMaybe,
      ...extendedDataMaybe,
    })
      .then(res => {
        console.log('res', res);
        if (isBookingLink) {
          guestUserCheckout(
            history,
            routeConfiguration,
            callSetInitialValues,
            onInitializeCardPaymentData
          );
        }
      })
      .catch(err => {
        console.log('err', JSON.parse(err));
      });
  };

  const confirmErrorMessage = confirmError ? (
    <div className={css.error}>
      {isSignupEmailTakenError(confirmError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  ) : null;

  return (
    <div className={css.content}>
      {isBookingLink ? <BookingDetailsBanner checkoutData={checkoutData} /> : null}

      <Heading as="h1" rootClassName={css.signupWithIdpTitle}>
        <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
      </Heading>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>
      {confirmErrorMessage}
      <ConfirmSignupForm
        className={css.form}
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        termsAndConditions={termsAndConditions}
        authInfo={authInfo}
        idp={idp}
        preselectedUserType={preselectedUserType}
        userTypes={userTypes}
        userFields={userFields}
      />
    </div>
  );
};

export const AuthenticationOrConfirmInfoForm = props => {
  const {
    tab,
    userType,
    authInfo,
    from,
    showFacebookLogin,
    showGoogleLogin,
    showAppleLogin,
    showSnapchatLogin,
    showTikTokLogin,
    submitLogin,
    submitSignup,
    submitSignupWithIdp,
    authInProgress,
    loginError,
    idpAuthError,
    signupError,
    confirmError,
    termsAndConditions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    history,
    routeConfiguration,
    isBookingLink,
    checkoutData,
  } = props;
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';

  return isConfirm ? (
    <ConfirmIdProviderInfoForm
      userType={userType}
      authInfo={authInfo}
      submitSignupWithIdp={submitSignupWithIdp}
      authInProgress={authInProgress}
      confirmError={confirmError}
      termsAndConditions={termsAndConditions}
      callSetInitialValues={callSetInitialValues}
      onInitializeCardPaymentData={onInitializeCardPaymentData}
      history={history}
      routeConfiguration={routeConfiguration}
      isBookingLink={isBookingLink}
      checkoutData={checkoutData}
    />
  ) : (
    <AuthenticationForms
      isLogin={isLogin}
      showFacebookLogin={showFacebookLogin}
      showGoogleLogin={showGoogleLogin}
      showAppleLogin={showAppleLogin}
      showSnapchatLogin={showSnapchatLogin}
      showTikTokLogin={showTikTokLogin}
      userType={userType}
      from={from}
      loginError={loginError}
      idpAuthError={idpAuthError}
      signupError={signupError}
      submitLogin={submitLogin}
      authInProgress={authInProgress}
      submitSignup={submitSignup}
      termsAndConditions={termsAndConditions}
      callSetInitialValues={callSetInitialValues}
      onInitializeCardPaymentData={onInitializeCardPaymentData}
      history={history}
      routeConfiguration={routeConfiguration}
      isBookingLink={isBookingLink}
      checkoutData={checkoutData}
    ></AuthenticationForms>
  );
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;
};

export const AuthenticationPageComponent = props => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());
  const [authError, setAuthError] = useState(getAuthErrorFromCookies());
  const config = useConfiguration();

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }
  }, []);
  useEffect(() => {
    window?.ReactNativeWebView && window.ReactNativeWebView.postMessage('NOW Web');
  }, [])
  // On mobile, it's better to scroll to top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const {
    authInProgress,
    currentUser,
    intl,
    isAuthenticated,
    location,
    params: pathParams,
    loginError,
    scrollingDisabled,
    signupError,
    submitLogin,
    submitSignup,
    confirmError,
    submitSignupWithIdp,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    callSetInitialValues,
    onInitializeCardPaymentData,
  } = props;

  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();

  const queryParams = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const isBookingLink = queryParams?.bookingLink === true;

  const checkoutData =
    typeof window !== 'undefined' && window.localStorage.getItem('checkoutData')
      ? JSON.parse(window.localStorage.getItem('checkoutData'))
      : {};

  // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom || authinfoFrom || null;

  const isConfirm = tab === 'confirm';
  const userTypeInPushState = location.state?.userType || null;
  const userTypeInAuthInfo = isConfirm && authInfo?.userType ? authInfo?.userType : null;
  const userType = pathParams?.userType || userTypeInPushState || userTypeInAuthInfo || 'customer';
  const isMerchant = userType === 'provider';

  const { userTypes = [] } = config.user;
  const preselectedUserType = userTypes.find(conf => conf.userType === userType)?.userType || null;
  const show404 = userType && !preselectedUserType;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const isLogin = tab === 'login';

  const { profile } = user?.attributes || {};
  const { publicData } = profile || {};
  const { isPhoneNumberVerified } = publicData || {};

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

  const { isDealListingPublished } = user?.attributes?.profile?.publicData || {};
  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <NamedRedirect name="LandingPage" />;
  } else if (
    isAuthenticated &&
    currentUserLoaded &&
    showEmailVerification &&
    !isDealListingPublished &&
    userType === 'provider'
  ) {
    return <NamedRedirect name="MerchantDetails" />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  } else if (isAuthenticated && currentUserLoaded && userType === 'customer') {
    if (!isPhoneNumberVerified) {
      return <NamedRedirect name="CustomerDetailsPage" />;
    }
    else {
      return <NamedRedirect name="LandingPage" />;
    }
  } else if (show404) {
    return <NotFoundPage staticContext={props.staticContext} />;
  }

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const marketplaceName = config.marketplaceName;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { marketplaceName });
  const schemaDescription = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionSignup' }, { marketplaceName });

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });
  const arabicLanguage =
    typeof window !== 'undefined' && localStorage.getItem('language') === 'Arabic';

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      className={arabicLanguage && css.arabicDirection}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      {isMerchant ? (
        showEmailVerification ? (
          <EmailVerificationInfo
            name={user.attributes.profile.firstName}
            email={<span className={css.email}>{user.attributes.email}</span>}
            onResendVerificationEmail={onResendVerificationEmail}
            resendErrorMessage={resendErrorMessage}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            currentUser={currentUser}
          />
        ) : (
          <AuthenticationPageMerchant
            tab={tab}
            userType={userType}
            authInfo={authInfo}
            from={from}
            showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
            showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
            showAppleLogin={!!process.env.REACT_APP_APPLE_CLIENT_ID}
            showSnapchatLogin={!!process.env.REACT_APP_SNAPCHAT_CLIENT_ID}
            showTikTokLogin={!!process.env.REACT_APP_TIKTOK_CLIENT_ID}
            submitLogin={submitLogin}
            submitSignup={submitSignup}
            submitSignupWithIdp={submitSignupWithIdp}
            authInProgress={authInProgress}
            loginError={loginError}
            idpAuthError={authError}
            signupError={signupError}
            confirmError={confirmError}
            termsAndConditions={
              <TermsAndConditions
                isMerchant={isMerchant}
                onOpenTermsOfService={() => setTosModalOpen(true)}
                onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                intl={intl}
              />
            }
          />
        )
      ) : (
        <LayoutSingleColumn
          mainColumnClassName={css.layoutWrapperMain}
          topbar={<TopbarContainer className={topbarClasses} merchantMaybe={isMerchant} />}
          footer={<CustomerFooter />}
        >
          <ResponsiveBackgroundImageContainer
            className={css.root}
            childrenWrapperClassName={css.contentContainer}
            as="section"
            image={config.branding.brandImage}
            sizes="100%"
            useOverlay
          >
            {showEmailVerification ? (
              <EmailVerificationInfo
                name={user.attributes.profile.firstName}
                email={<span className={css.email}>{user.attributes.email}</span>}
                onResendVerificationEmail={onResendVerificationEmail}
                resendErrorMessage={resendErrorMessage}
                sendVerificationEmailInProgress={sendVerificationEmailInProgress}
                currentUser={currentUser}
              />
            ) : (
              <AuthenticationOrConfirmInfoForm
                tab={tab}
                userType={userType}
                authInfo={authInfo}
                from={from}
                showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
                showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
                showAppleLogin={!!process.env.REACT_APP_APPLE_CLIENT_ID}
                showSnapchatLogin={!!process.env.REACT_APP_SNAPCHAT_CLIENT_ID}
                showTikTokLogin={!!process.env.REACT_APP_TIKTOK_CLIENT_ID}
                submitLogin={submitLogin}
                submitSignup={submitSignup}
                submitSignupWithIdp={submitSignupWithIdp}
                authInProgress={authInProgress}
                loginError={loginError}
                idpAuthError={authError}
                signupError={signupError}
                confirmError={confirmError}
                callSetInitialValues={callSetInitialValues}
                onInitializeCardPaymentData={onInitializeCardPaymentData}
                history={history}
                routeConfiguration={routeConfiguration}
                isBookingLink={isBookingLink}
                checkoutData={checkoutData}
                termsAndConditions={
                  <TermsAndConditions
                    onOpenTermsOfService={() => setTosModalOpen(true)}
                    onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                    intl={intl}
                  />
                }
              />
            )}
          </ResponsiveBackgroundImageContainer>
        </LayoutSingleColumn>
      )}
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        className={arabicLanguage && css.arabicDirection}
      >
        <div className={css.termsWrapper}>
          {/* <TermsOfServiceContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          /> */}
          <div className={css.termContainer}>
            <H1 className={css.title}>
              <FormattedMessage id="AuthenticationPage.termsService" />
            </H1>
            <p className={css.text}>
              <FormattedMessage id="AuthenticationPage.everyMarketplace" />
            </p>
            <p className={css.text}>
              <FormattedMessage id="AuthenticationPage.findTemplate" />{' '}
              <a href="#" className={css.link}>
                <FormattedMessage id="AuthenticationPage.here" />{' '}
              </a>
              .
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        className={arabicLanguage && css.arabicDirection}
      >
        <div className={css.privacyWrapper}>
          {/* <PrivacyPolicyContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          /> */}
          <div className={css.termContainer}>
            <H1 className={css.title}>
              <FormattedMessage id="AuthenticationPage.privacyPolicy" />
            </H1>
            <p className={css.text}>
              <FormattedMessage id="AuthenticationPage.everyMarketplacePrivacy" />
            </p>
            <p className={css.text}>
              <FormattedMessage id="AuthenticationPage.findTemplate" />{' '}
              <a href="#" className={css.link}>
                <FormattedMessage id="AuthenticationPage.here" />{' '}
              </a>
              .
            </p>
          </div>
        </div>
      </Modal>
    </Page>
  );
};

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
  privacyAssetsData: null,
  privacyFetchInProgress: false,
  privacyFetchError: null,
  tosAssetsData: null,
  tosFetchInProgress: false,
  tosFetchError: null,
};

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // to fetch privacy-policy page asset
  // which is shown in modal
  privacyAssetsData: object,
  privacyFetchInProgress: bool,
  privacyFetchError: propTypes.error,

  // to fetch terms-of-service page asset
  // which is shown in modal
  tosAssetsData: object,
  tosFetchInProgress: bool,
  tosFetchError: propTypes.error,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, initialValues, saveToSessionStorage) =>
    dispatch(setInitialValues(initialValues, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
